<p-menu #profileMenu [popup]="true" [model]="profileMenuItems"></p-menu>

<p-sidebar class="lg:hidden" [(visible)]="displaySidebar" [modal]="false">
    <nav class="sidebar-mobile h-full flex flex-column " *ngIf="logged && !board && !demo" aria-label="Mobile">
        <ng-container [ngTemplateOutlet]="menu" ></ng-container>
    </nav>
</p-sidebar>

<p-toolbar class="toolbar" *ngIf="logged && !board && !demo" >
    <div class="p-toolbar-group-left">
        <img class="logo lg:hidden align-self-start" [src]="appVersion === 'engaged' ? './assets/img/logo-mobile-d.png' : './assets/img/logo-mobile.png'" alt="logo sciculture">
        <button pButton pRipple type="button" icon="pi pi-bars" class="p-button-rounded p-button-text lg:hidden" (click)="displaySidebar = true"></button>
    </div>

    <div class="p-toolbar-group-right">
        <button  pButton pRipple icon="pi pi-angle-down" iconPos="right" type="button" [label]="userName" class="p-button-text hidden lg:flex" (click)="profileMenu.toggle($event)">
            <img *ngIf="userImage" [src]="userImage" width="50" height="50" alt="foto profilo" class="profile-image">
            <img *ngIf="!userImage" src="../../../assets/img/placeholder-profile.svg" width="50" height="50" alt="foto profilo" class="profile-image">
        </button>
        <button pButton pRipple type="button" class="p-button-text lg:hidden" (click)="profileMenu.toggle($event)">
            <img *ngIf="userImage" [src]="userImage" width="50" height="50" alt="foto profilo" class="profile-image">
            <img *ngIf="!userImage" src="../../../assets/img/placeholder-profile.svg" width="50" height="50" alt="foto profilo" class="profile-image">
        </button>
    </div>
</p-toolbar>

<nav class="sidebar hidden lg:flex flex-column" *ngIf="logged && !board && !demo" aria-label="Principale">
    <ng-container [ngTemplateOutlet]="menu" ></ng-container>
</nav>

<ng-template #menu>
    <a href="https://sciculture.eu" target="_blank">
        <img class="logo hidden lg:block align-self-start" [src]="appVersion === 'engaged' ? './assets/img/logo-d.png' : './assets/img/logo.png'" alt="logo sciculture">
    </a>
    <ul class="menu flex-1">
        <li *ngIf="appVersion === 'engaged'">
            <a routerLinkActive="active" [routerLink]="['/home']" role="menuitem" class="px-3 py-3">
                <i class="pi pi-home text-xl"></i>
                Home
            </a>
        </li>
        <li>
            <a routerLinkActive="active" [routerLink]="['/dashboard']" role="menuitem" class="px-3 py-3">
                <i class="pi pi-table text-xl"></i>
                All boards
            </a>
        </li>
        <li>
            <a routerLinkActive="active" [routerLink]="['/private']" role="menuitem" class="px-3 py-3">
                <i class="pi pi-lock text-xl"></i>
                Private boards
                <p-tag *ngIf="newPrivateBoards > 0" [value]="'' + newPrivateBoards+ ''" severity="warning" [rounded]="true" class="ml-2"></p-tag>
            </a>
        </li>
        <li>
            <a routerLinkActive="active" [routerLink]="['/shared-others']" role="menuitem" class="px-3 py-3">
                <i class="pi pi-key text-xl"></i>
                Shared with others
                <p-tag *ngIf="newSharedWithOthersBoards > 0" [value]="'' + newSharedWithOthersBoards + ''" severity="warning" [rounded]="true" class="ml-2"></p-tag>
            </a>
        </li>
        <li>
            <a routerLinkActive="active" [routerLink]="['/shared-me']" role="menuitem" class="px-3 py-3">
                <i class="pi pi-users text-xl"></i>
                Shared with me
                <p-tag *ngIf="newSharedWithMeBoards > 0" [value]="'' + newSharedWithMeBoards + ''" severity="warning" [rounded]="true" class="ml-2"></p-tag>
            </a>
        </li>
        <hr *ngIf="userRole == 'admin'">
        <li *ngIf="userRole == 'admin'">
            <a routerLinkActive="active" [routerLink]="['/users']" role="menuitem" class="px-3 py-3">
                <i class="pi pi-user-plus text-xl"></i>
                Manage users
            </a>
        </li>
    </ul>

    <footer class="footer px-3 pt-3">
        <p class="text-400"><small>The European Commission's support for the production of this publication does not constitute an endorsement of the contents, which reflect the views only of the authors, and the Commission cannot be held responsible for any use which may be made of the information contained therein.</small></p>
        <img src="./assets/img/EU flag-Erasmus+_vect_POS [RGB].svg" class="eu-logo" width="145" alt="eu cofunded logo">
        <p class="text-800"><small>© SciCulture. All Rights Reserved.</small></p>
    </footer>
</ng-template>

<p-dialog [(visible)]="displaySurvey" position="bottom" [resizable]="false" [draggable]="false" [modal]="true" [style]="{width: '620px'}" (onHide)="saveSurveyPreference()">
    <ng-template pTemplate="header">
        Survey
    </ng-template>

    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam malesuada porttitor. Nullam sed ex nec lorem fermentum cursus. Suspendisse potenti. Nunc pulvinar facilisis nunc, sit amet pulvinar mauris condimentum ut. Quisque vel ultrices elit.</p>
    <div class="p-fluid">
        <div class="p-field mb-2">
            <button target="new" pButton type="button" label="Go to the Google Form" class="p-button-info" (click)="openSurvey()"></button>
        </div>
        <div class="p-field-checkbox">
            <p-checkbox name="survey_done" label="Do not remind me this" [(ngModel)]="surveyDone" inputId="survey_done" value="survey_done"></p-checkbox>
        </div>
    </div>

    <p-footer></p-footer>
</p-dialog>

<router-outlet></router-outlet>