import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthInterceptorService } from './_helpers/auth-interceptor.service';
import { ErrorInterceptorService } from './_helpers/error-interceptor.service';
// import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers/auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  // { path: '**', component: LoginComponent },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
  { path: 'dashboard', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate:[AuthGuard] },
  { path: 'private', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate:[AuthGuard] },
  { path: 'shared-others', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate:[AuthGuard] },
  { path: 'shared-me', loadChildren: () => import('./components/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate:[AuthGuard] },
  { path: 'board/:id', loadChildren: () => import('./components/board/board.module').then(m => m.BoardModule), canActivate:[AuthGuard] },
  { path: 'demo', loadChildren: () => import('./components/board/board.module').then(m => m.BoardModule) },
  { path: 'profile/:id', loadChildren: () => import('./components/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'users', loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule), canActivate:[AuthGuard] },
  { path: 'activate/:hash', loadChildren: () => import('./activate/activate.module').then(m => m.ActivateModule) },
  { path: 'confirm', loadChildren: () => import('./confirm/confirm.module').then(m => m.ConfirmModule) },
  { path: 'change-password', loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordModule) },
  { path: 'recovery/:hash', loadChildren: () => import('./recovery/recovery.module').then(m => m.RecoveryModule) },
  { path: 'privacy-policy', loadChildren: () => import('./components/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  { path: 'term-use', loadChildren: () => import('./components/term-use/term-use.module').then(m => m.TermUseModule) },
  { path: 'help', loadChildren: () => import('./components/help/help.module').then(m => m.HelpModule) },
  { path: 'feedback', loadChildren: () => import('./components/feedback/feedback.module').then(m => m.FeedbackModule) },
  { path: 'home', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true }
  ]
})
export class AppRoutingModule { }
